<template>
<div>
    <div class="wrap">
        <div class="con_top">
            <h2><span>■</span> 관리자수정</h2>
        </div>
        <div class="con">
            <div class="con_table">
                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                    <tbody>
                        <tr>
                            <th style="width:111px;">아이디 <span class="red">*</span></th>
                            <td class="left">
                                <input type="text" v-model="info.id" class="ml-20" style="width:200px;" id="uid" name="uid" disabled> <br>
                                <span class="ml-20"> 영문 대/소문자 + 숫자포함 6~20자</span>

                            </td>
                        </tr>
                        <tr>
                            <th>암호 <span class="red">*</span></th>
                            <td class="left">
                                <input type="pwd" v-model="info.pwd" class="ml-20" style="width:200px;" id="pwd" name="pwd" value=""> <br>
                                <span class="ml-20"> 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이름 <span class="red">*</span></th>
                            <td class="left">
                                <input type="text" v-model="info.name" class="ml-20" style="width:200px;" id="uname" name="uname">
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 111px;">메모</th>
                            <td class="left">
                                <textarea v-model="info.memo" type="text" class="ml-20 w-98per h-100px" id="introduce" name="introduce" value="" />
                                </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btns3 mgB80">
                    <a class="btn_sms mr-10 pointer" @click="moveAccountPage()">취소</a>
                    <a class="btn_cancle mr-10 pointer" @click="remove(idx)">삭제</a>
                    <a class="btn_cancle pointer" @click="modify()">수정</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { IDFormatCheck, PWFormatCheck, EmptyCheck } from '@/utils/validation.js'
export default {
    data: () => ({
        idx: '',
        info: {
          name: '', // 이름
          id: '', // 아이디
          pwd: '', // 비밀번호
          memo: '',
        }
    }),

    mounted() {
      this.idx = this.$route.params.idx;
      // TODO: 관리자 계정 데이터 불러오기 api 호출
      this.get();
    },

    methods: {
        // 관리자계정 페이지로 돌아가기
        moveAccountPage () {
            this.$router.push('/admin/account')
        },

        // 관리자 계정 수정
        modify () {
          console.info(this.info);
            // 이름 검사
            if (!EmptyCheck(this.info.name, '성명을')) {
                return false
            }

            // 아이디 검사
            /* if (!EmptyCheck(this.info.id, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.info.id)) {
                    return false
                }
            } */

            // 비밀번호 검사
            if (!EmptyCheck(this.info.pwd, '암호를')) {
                return false
            } else {
                if (!PWFormatCheck(this.info.pwd)) {
                    return false
                }
            }

            // TODO: 관리자 계정 등록 API호출
            let param = {
              idx: this.info.idx,
              id: this.info.id,
              name: this.info.name,
              pwd: this.info.pwd,
              memo: this.info.memo,
            };

            this.axios.post("/api/v1/admin/modify", param).then((res) => {
              if(res.data.err === 0) {
                this.$router.push('/admin/account')
              } else {
                alert(res.data.err_msg);
              }
            });
        },

        get() {
          let param = {
            idx: this.$route.params.idx
          }

          this.axios.get("/api/v1/solution/info/view_admin_member", {params:param}).then((res) => {
            if(res.data.err === 0) {
                
              this.info = res.data.info;
              this.info.pwd = "";
            } else {
              alert(res.data.err_msg);
            }
          });
        },

         // 관리자계정 삭제
        remove(idx) {
            console.log('idx : ' , idx);
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                let param = {idx: Number(idx)}
                this.axios.post("/api/v1/solution/deldata/crm_staff", param).then((res) => {
                    if(res.data.err === 0) {
                        alert('삭제되었습니다.')
                        this.$router.push('/admin/account');
                    } else {
                        alert(res.data.err_msg);
                    }
                });
            } else {
                return false
            }
        }
    }
}
</script>
